@import "../../style.scss";

.topNavbar {
  @include themify($themes) {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 50px;
    position: sticky;
    top: 0;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;
    z-index: 888;

    @include mobile {
      height: 70px;
    }

    @include tablet {
      height: 70px;
    }

    .navbarTop {
      display: flex;
      align-items: center;
      border-bottom: 1px solid themed("border");
      justify-content: space-between;

      .topNavbar-left {
        display: flex;
        align-items: center;
        gap: 30px;
        position: relative;

        .link {
          text-decoration: none;
          font-family: "RubikMedium";
          font-weight: 500;

          .logo {
            font-size: 35px;
            color: themed("logo");

            @include mobile {
              font-size: 30px;
            }
          }
        }

        .search {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid themed("border");
          border-radius: 5px;
          padding: 5px;
          background-color: transparent;
          transition-duration: 0.1s;
          position: relative;

          .search-icon {
            @include mobile {
              display: none;
            }
            @include tablet {
              display: none;
            }
          }

          .mobile-search-btn {
            display: none;

            @include mobile {
              display: block;
            }
            @include tablet {
              display: block;
            }
          }

          input {
            border: none;
            width: 500px;
            outline: none;
            background-color: transparent;
            color: themed("textColor");
            font-family: "RubikRegular";
            font-size: 15px;

            @include mobile {
              display: none;
            }

            @include tablet {
              width: 300px;
            }
          }

          .main-search-result {
            max-height: 70vh;
            background-color: themed("cardColor");
            border: 1px solid themed("border");
            border-radius: 0 0 2px 2px;
            padding: 5px 8px;
            position: absolute;
            top: 45px;
            width: 100%;
            overflow-y: scroll;
            z-index: 999;

            &::-webkit-scrollbar {
              width: 8px; /* Width of the scrollbar */
            }

            &::-webkit-scrollbar-track {
              background: themed("border"); /* Color of the track */
            }

            &::-webkit-scrollbar-thumb {
              background: themed("cardColor"); /* Color of the thumb */
              border-radius: 5px; /* Radius of the thumb */
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #7d7d7e; /* Color of the thumb on hover */
              cursor: pointer;
            }

            .desktopLink {
              display: flex;
              align-items: center;
              gap: 10px;
              text-decoration: none;
              color: themed("textColor");
              padding-bottom: 7px;
              margin-bottom: 7px;
              border-bottom: 1px solid themed("border");

              &:last-child {
                border-bottom: none;
              }

              .search-result-left {
                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 5px;
                  object-fit: cover;
                }
              }

              .search-result-right {
                display: flex;
                flex-direction: column;
                gap: 3px;

                .name {
                  font-family: "Helvetica";
                  font-weight: 600;
                  font-size: 14px;
                }

                .content {
                  font-family: "Helvetica";
                  font-size: 12px;
                }
              }
            }
          }
        }

        .search:hover {
          border: 1px solid themed("border");
          background-color: transparent;
        }

        .mobile-search {
          position: absolute;
          top: 50px;
          width: 96vw;
          z-index: 99;

          .search-bar {
            border: 1px solid themed("border");
            border-radius: 5px;
            padding: 5px 2px;
            transition-duration: 0.1s;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2px;
            background-color: themed("cardColor");

            input {
              border: none;
              outline: none;
              background-color: transparent;
              color: themed("textColor");
              font-family: "RubikRegular";
              font-size: 15px;
              padding: 2px;

              @include tablet {
                margin-left: -20px;
                width: 90%;
              }
            }

            &:hover {
              border: 1px solid themed("border");
            }

            .trigger-btn {
              position: absolute;
              right: 0;
              padding-right: 8px;
            }
          }

          .search-result {
            max-height: 83vh;
            background-color: themed("cardColor");
            border: 1px solid themed("border");
            border-radius: 0 0 2px 2px;
            padding: 5px 8px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 8px; /* Width of the scrollbar */
            }

            &::-webkit-scrollbar-track {
              background: themed("border"); /* Color of the track */
            }

            &::-webkit-scrollbar-thumb {
              background: themed("cardColor"); /* Color of the thumb */
              border-radius: 5px; /* Radius of the thumb */
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #7d7d7e; /* Color of the thumb on hover */
              cursor: pointer;
            }

            .mobileLink {
              display: flex;
              align-items: center;
              gap: 10px;
              text-decoration: none;
              color: themed("textColor");
              padding-bottom: 7px;
              margin-bottom: 7px;
              border-bottom: 1px solid themed("border");

              &:last-child {
                border-bottom: none;
              }

              .search-result-left {
                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 5px;
                  object-fit: cover;
                }
              }

              .search-result-right {
                display: flex;
                flex-direction: column;
                gap: 3px;

                .name {
                  font-family: "Helvetica";
                  font-weight: 600;
                  font-size: 14px;
                }

                .content {
                  font-family: "Helvetica";
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .topNavbar-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        position: relative;
        left: -50px;

        @include mobile {
          gap: 10px;
          margin-right: -17px;
        }

        @include tablet {
          gap: 10px;
          margin-right: -45px;
        }

        .navbar-icon {
          color: themed("logo");
          font-size: 25px;
          cursor: pointer;

          @include tablet {
            display: none;
          }
        }

        .notificaions {
          position: relative;

          p {
            background-color: #df0404;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -7px;
            right: -7px;
            font-size: 12px;
            color: white;
            font-family: "RubikRegular";
          }
        }

        .dark-mode-switch {
          color: themed("logo");
          font-size: 25px;
          cursor: pointer;

          @include mobile {
            display: none;
          }
        }

        .profile-img {
          position: relative;

          @include mobile {
            display: none;
          }

          @include tablet {
            display: none;
          }

          img {
            width: 40px;
            height: 40px;
            border: 2px solid themed("primary");
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;

            @include mobile {
              display: none;
            }

            @include tablet {
              display: none;
            }
          }

          img:focus {
            outline: none;
            background-color: transparent;
          }

          img:active {
            outline: none !important;
            background-color: transparent !important;
          }
        }

        // Mobile menu controller
        .profile-img-mobile {
          display: none;

          @include mobile {
            display: block;
          }

          @include tablet {
            display: block;
          }

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid themed("primary");
            cursor: pointer;
          }

          img:focus {
            outline: none;
            background-color: transparent;
          }

          img:active {
            outline: none !important;
            background-color: transparent !important;
          }
        }
      }
    }

    .navbarBottom {
      display: none;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      @include mobile {
        display: flex;
      }
      @include tablet {
        display: flex;
      }

      .links {
        padding: 0 5px;
      }

      .navbarLink {
        border-bottom: 2px solid themed("primary");
      }

      .navbar-icon {
        color: themed("primary");
        font-size: 28px;
        cursor: pointer;
      }

      .notificaions {
        position: relative;

        p {
          background-color: #df0404;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          padding: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -7px;
          right: -7px;
          font-size: 12px;
          color: white;
          font-family: "RubikRegular";
        }
      }

      .dark-mode-switch {
        color: themed("logo");
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
}
