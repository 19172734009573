@import "../../style.scss";

.errorBoundary {
  @include themify($themes) {
    flex: 2;
    background-color: themed("bgSoft");
    margin-top: -25px;
    padding: 20px 70px;
    color: themed("textColor");
    min-height: 100vh;
    font-family: "RubikRegular";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @include mobile {
      h1 {
        width: 100%;
        font-size: 18px;
        font-weight: normal;
      }
    }
    @include tablet {
      font-size: 18px;
    }
  }
}
