@import "../../../style.scss";

.interactionsLoading {
  @include themify($themes) {
    flex: 2;
    background-color: themed("bgSoft");
    margin-top: -25px;
    padding: 20px 70px;
    color: themed("textColorSoft");
    min-height: 100vh;

    @include mobile {
      padding: 7px 4px;
    }

    @include tablet {
      padding: 7px 4px;
    }

    .loadingCard {
      background-color: themed("cardColor");
      padding: 20px;

      .isloading {
        margin-left: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 10px 0;

        .left {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          background-color: #888888a6;
          animation: children 1s ease infinite;
        }

        .right {
          height: 45px;
          width: 90%;
          border-radius: 4px;
          background-color: #888888a6;
          animation: children 1s ease infinite;
        }
      }
    }

    @keyframes children {
      to {
        opacity: 0.7;
      }
    }
  }
}
