@import "../../style.scss";

.messaging {
  @include themify($themes) {
    position: fixed;
    right: 10px;
    bottom: 0px;
    justify-content: baseline;
    border-radius: 5px 5px 0 0;
    border: 2px solid themed("primary");
    border-bottom: 0;
    width: 510px;
    max-height: 550px;
    z-index: 888;
    color: themed("textColor");

    @include mobile {
      display: none;
    }

    @include tablet {
      display: none;
    }

    @include phoneDesktop {
      display: none;
    }

    .messaging-header {
      background-color: themed("primary");
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "RubikMedium";
      font-size: 16px;
      color: #fff;
      height: 30px;

      .messaging-header-right {
        display: flex;
        align-items: center;
        gap: 7px;

        .icon {
          font-size: 30px;
          font-weight: 700;
        }

        .notification {
          position: relative;

          .notification-count {
            background-color: #df0404;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -1px;
            right: -5px;
            font-size: 11px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .switch {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .messaging-body {
      background-color: themed("cardColor");
      padding: 0;
      display: flex;
      height: 89%;
      flex: 1;
      position: relative;

      .messaging-body-left {
        flex: 2.4;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 33px;
        display: flex;
        flex-direction: column;
        gap: 1px;

        &::-webkit-scrollbar {
          width: 8px; /* Width of the scrollbar */
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1; /* Color of the track */
        }

        &::-webkit-scrollbar-thumb {
          background: #c0c0c2; /* Color of the thumb */
          border-radius: 5px; /* Radius of the thumb */
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #7d7d7e; /* Color of the thumb on hover */
          cursor: pointer;
        }

        .searchUser {
          padding-top: 5px;
          background-color: whitesmoke;

          input {
            background-color: whitesmoke;
            padding: 5px;
            border: none;
            border-bottom: 2px solid #888888;
            outline: none;
            font-family: "RubikRegular";
          }
        }

        .useList {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          background-color: themed("chatLeftBg");
          padding: 10px 5px;
          transition: 0.2s ease;

          &:hover {
            cursor: pointer;
            background-color: themed("border");
          }

          .useList-left {
            position: relative;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              object-fit: cover;
            }

            .onlineStatus {
              position: absolute;
              top: 15px;
              left: 20px;

              img {
                height: 12px;
                width: 12px;
              }
            }

            .offlineStatus {
              position: absolute;
              background-color: #b7b8b7;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              right: 0;
              bottom: 3px;
            }
          }

          .useList-right {
            font-family: "RubikRegular";
            width: 100%;

            .userName {
              font-size: 14px;
              font-weight: 600;
            }

            .lastMessage {
              font-size: 12px;
            }
          }
        }
      }

      .messaging-body-right {
        flex: 5;
        background-color: themed("bgSoft");
        padding: 0;
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;
        position: relative;
        width: 60%;

        .incomingUser {
          background-color: themed("cardColor");
          position: sticky;
          top: 0;
          padding: 9px;
          display: flex;
          align-items: center;
          gap: 10px;
          border-bottom: 1px solid themed("borderTwo");
          z-index: 99;

          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
          }

          .name {
            font-family: "Helvetica";
            font-size: 16px;
            font-weight: 600;
            color: themed("textColor");
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            p {
              font-size: 12px;
              font-weight: normal;
            }
          }
        }

        .chat-screen {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 7px;
          height: 100%;
          // margin-bottom: 9.5em;
          padding-top: 7px;
          padding-right: 7px;
          padding-left: 7px;
          padding-bottom: 1em;
          overflow-y: scroll;
          // max-width: 300px;

          &::-webkit-scrollbar {
            width: 8px; /* Width of the scrollbar */
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1; /* Color of the track */
          }

          &::-webkit-scrollbar-thumb {
            background: #c0c0c2; /* Color of the thumb */
            border-radius: 5px; /* Radius of the thumb */
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #7d7d7e; /* Color of the thumb on hover */
            cursor: pointer;
          }

          .msgs {
            display: flex;
            margin-bottom: 0;
            width: 60%;
          }

          .incoming-msg {
            background-color: themed("chatIncomingBg");
            padding: 5px;
            border-radius: 5px;
            width: 70%;
            font-family: "RubikRegular";
            font-size: 14px;
            align-self: flex-start;
            color: #fff;
            z-index: 0;
          }

          .outgoing-msg {
            z-index: 0;
            background-color: themed("chatOutgoingBg");
            padding: 5px;
            border-radius: 5px;
            width: 70%;
            font-family: "RubikRegular";
            align-self: flex-end;
            color: #fff;
            font-size: 14px;
          }

          .msg-details {
            display: flex;
            flex-direction: column;
            width: 100%; /* Allow messages to wrap if too long */

            img {
              height: 300px;
              border-radius: 5px;
              object-fit: cover;
              margin-bottom: 5px;
            }

            p {
              margin: 0;
              font-weight: normal;
              font-family: "RubikRegular";
              font-size: 14px;
              white-space: pre-wrap;
              overflow-wrap: break-word;
            }

            small {
              margin-top: 5px;
              font-size: 0.7rem;
              margin-top: 0.5rem;
              font-family: "RubikRegular";
              font-style: italic;
              text-align: right;
            }
          }

          .startChat {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "RubikRegular";
            width: 100%;
          }
        }

        .inputForm {
          z-index: 2;
          position: sticky;
          background-color: themed("cardColor");

          form {
            margin-left: -7px;
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 2px;
            padding: 10px;

            .left {
              width: 80%;

              textarea {
                resize: none;
                padding: 5px;
                width: 95%;
                outline: none;
                border-radius: 5px;
                border: 1px solid themed("addPost");
                background-color: transparent;
                color: themed("textColor");
                font-family: "RubikRegular";

                &::-webkit-scrollbar {
                  width: 4px; /* Width of the scrollbar */
                }

                &::-webkit-scrollbar-track {
                  background: #f1f1f1; /* Color of the track */
                }

                &::-webkit-scrollbar-thumb {
                  background: #c0c0c2; /* Color of the thumb */
                  border-radius: 5px; /* Radius of the thumb */
                }

                &::-webkit-scrollbar-thumb:hover {
                  background: #7d7d7e; /* Color of the thumb on hover */
                  cursor: pointer;
                }

                &::placeholder {
                  font-style: italic;
                }
              }
            }

            .right {
              display: flex;
              align-items: center;
              gap: 2px;
              width: 27%;

              .icon {
                font-size: 18px;

                &:hover {
                  cursor: pointer;
                }
              }

              input {
                display: none;
              }

              img {
                width: 20px;
                height: 20px;

                &:hover {
                  cursor: pointer;
                }
              }

              button {
                padding: 5px;
                background-color: themed("primary");
                color: #fff;
                border: 1px solid themed("border");
                border-radius: 5px;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

        .emptyChat {
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            margin-top: 200px;
            text-align: center;
            font-size: 30px;
            font-family: "RubikRegular";
            font-weight: 600;
            flex: 1;
          }
        }
      }
    }
  }
}
