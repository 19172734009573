@import "../../../../style.scss";

.profileEditLoading {
  @include themify($themes) {
    flex: 2;
    background-color: themed("bgSoft");
    margin-top: -25px;
    padding: 20px 70px;
    color: themed("textColorSoft");
    min-height: 100vh;

    @include mobile {
      padding: 7px 4px;
    }

    @include tablet {
      padding: 7px 4px;
    }

    .theLoading {
      background-color: themed("cardColor");
      padding: 20px;

      .top {
        width: 70%;
        height: 30px;
        background-color: #888888a6;
        margin-bottom: 20px;
        border-radius: 3px;
        animation: children 0.5s ease infinite;
      }

      .img {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 30px;
        margin-bottom: 20px;

        div {
          height: 80px;
          width: 80px;
          background-color: #888888a6;
          border-radius: 3px;
          animation: children 0.5s ease infinite;
        }
      }

      .others {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .inputs {
          background-color: #888888a6;
          width: 100%;
          height: 30px;
          border-radius: 3px;
          animation: children 0.5s ease infinite;
        }
      }
    }

    @keyframes children {
      to {
        opacity: 0.7;
      }
    }
  }
}
